<template>
  <div>
    <van-field
      readonly
      clickable
      name="picker"
      :value="selectedValue"
      :label="label"
      placeholder="请选择"
      @click="showPicker = true"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        value-key="label"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedValue: undefined,
      showPicker: false
    }
  },
  props: {
    value: {
      type: [String, Number]
    },
    label: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    value(now) {
      if (!now && now !== 0) {
        this.selectedValue = now
      }
    }
  },
  methods: {
    onConfirm(column) {
      this.selectedValue = column.label
      this.$emit('input', column.value)
      this.showPicker = false
    }
  }
}
</script>

<style lang="less" scoped></style>

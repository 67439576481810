import axios from '@/utils/request'

export function checkPass(pass) {
  return axios({
    url: '/external/checkPass',
    method: 'get',
    headers: {
      pass
    }
  })
}

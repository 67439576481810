import Vue from 'vue'
import Vuex from 'vuex'
import { APP_MUTATIONS } from '@/store/storage-types'
import { DEVICE_TYPE } from '@/utils/device'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    device: DEVICE_TYPE.DESKTOP
  },
  getters: {
    device: (state) => state.device
  },
  mutations: {
    // 当前设备类型
    [APP_MUTATIONS.TOGGLE_DEVICE]: (state, device) => {
      state.device = device
    }
  },
  actions: {},
  modules: {}
})

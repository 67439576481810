<template>
  <div>
    <van-sticky>
      <van-nav-bar
        :title="'查看联系人(' + record.whatsId + ')'"
        left-text="返回"
        left-arrow
        @click-left="handleBack"
      />
      <van-search
        v-model="queryParam.friendWhatsId"
        placeholder="请输入客服号"
        show-action
        @search="reloadTable(true)"
      >
        <template #action>
          <div @click="reloadTable(true)">查询</div>
        </template>
      </van-search>
    </van-sticky>
    <!-- 列表 -->
    <van-pull-refresh v-model="loading" @refresh="resetSearchForm">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="reloadTable"
      >
        <van-card
          v-for="item in dataSource"
          :key="item.id"
          :desc="item.pushName"
        >
          <template #title>
            {{ item.friendWhatsId }}
          </template>
          <template #thumb>
            <a-avatar
              shape="square"
              icon="user"
              :src="item.avatar"
              :size="70"
            />
          </template>
          <template #price>
            {{ item.countryCode }}
          </template>
          <template #num>
            {{ item.createAt }}
          </template>
        </van-card>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ListPageMixin } from '@/mixins'
import { queryContactsPage } from '@/api/home'

export default {
  data() {
    return {
      getPage: queryContactsPage
    }
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [ListPageMixin],
  created() {
    this.queryParam.whatsId = this.record.whatsId
  },
  methods: {
    resetSearchForm() {
      this.queryParam.whatsId = this.record.whatsId
      this.reloadTable(true)
    },
    handleBack() {
      this.$emit('goBack')
    }
  }
}
</script>

<style lang="less" scoped></style>

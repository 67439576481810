const accountStates = [
  {
    label: '在线',
    value: 10,
    color: '#1890ff'
  },
  {
    label: '掉线',
    value: 20,
    color: '#f59a23'
  },
  {
    label: '离线',
    value: 30,
    color: '#aaaaaa'
  },
  {
    label: '封号',
    value: 40,
    color: '#d9001b'
  }
]

const platforms = [
  {
    get label() {
      return 'WS'
    },
    value: 1,
    color: '#70b603'
  },
  {
    get label() {
      return 'TG'
    },
    value: 2,
    color: '#1990ff'
  }
  // {
  //   get label() {
  //     return 'Messenger'
  //   },
  //   value: 3
  // }
]

const equipments = [
  {
    label: 'PC',
    value: 1
  },
  {
    label: '手机',
    value: 2
  }
]

const behaviorTypes = [
  {
    label: 'PC扫码登录',
    value: 11
  },
  {
    label: 'PC上线',
    value: 12
  },
  {
    label: '手机上线',
    value: 13
  },
  {
    label: 'PC掉线',
    value: 21
  },
  {
    label: '手机掉线',
    value: 22
  },
  {
    label: 'PC关闭客服号离线',
    value: 31
  },
  {
    label: 'PC关闭客户端离线',
    value: 32
  },
  {
    label: 'PC触发监控下线',
    value: 33
  },
  {
    label: '手机删除下线',
    value: 34
  },
  {
    label: '手机主动退出下线',
    value: 35
  },
  {
    label: '手机触发监控下线',
    value: 36
  },
  {
    label: '手机异常下线',
    value: 37
  },
  {
    label: 'PC封号',
    value: 41
  },
  {
    label: '手机封号',
    value: 42
  },
  {
    label: '系统标记永久封号',
    value: 43
  }
]

const isRecordings = [
  {
    label: '是',
    value: 1
  },
  {
    label: '否',
    value: 0
  }
]

const isBusinessNumbers = [
  {
    label: '是',
    value: 1
  },
  {
    label: '否',
    value: 0
  }
]

const isBusiness = [
  {
    label: '商业',
    value: 1
  },
  {
    label: '个人',
    value: 0
  }
]

const sources = [
  {
    label: '手机号',
    value: 0
  },
  {
    label: '二维码',
    value: 1
  }
]

const repeatCounts = [
  {
    label: '1次',
    value: 1
  },
  {
    label: '2次',
    value: 2
  },
  {
    label: '3次',
    value: 3
  },
  {
    label: '4次',
    value: 4
  },
  {
    label: '5次',
    value: 5
  },
  {
    label: '大于5次',
    value: 6
  }
]

const findItem = (arr, value) => {
  return arr.find((item) => item.value === value) || {}
}

export {
  accountStates,
  findItem,
  platforms,
  equipments,
  behaviorTypes,
  isRecordings,
  isBusinessNumbers,
  sources,
  repeatCounts,
  isBusiness
}

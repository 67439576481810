<template>
  <div style="padding: 16px">
    <div v-if="visible === 1">
      <StatisticsWithIcon :data="statisticsData" style="margin-bottom: 16px" />
      <div class="ant-pro-table-search">
        <a-form
          :form="queryParam"
          v-bind="searchFormLayout"
          @keydown.enter.native="reloadTable"
        >
          <a-row :gutter="16">
            <a-col :md="6" :sm="24">
              <a-form-item label="平台">
                <a-select v-model="queryParam.platform" allow-clear>
                  <a-select-option
                    v-for="item in platforms"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="客服号">
                <a-input v-model="queryParam.whatsId" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="PC状态">
                <a-select v-model="queryParam.survivalStatus" allow-clear>
                  <a-select-option
                    v-for="item in accountStates"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="WaChat状态">
                  <a-select v-model="queryParam.mobileOnlineStatus" allow-clear>
                    <a-select-option
                      v-for="item in accountStates"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="创建时间">
                  <a-range-picker
                    v-model="queryParam.createTime"
                    :show-time="{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'HH:mm:ss'),
                        moment('23:59:59', 'HH:mm:ss')
                      ]
                    }"
                    @change="changeCreateTime"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="昵称">
                  <a-input v-model="queryParam.nickname" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="商业号">
                  <a-select v-model="queryParam.businessType" allow-clear>
                    <a-select-option
                      v-for="item in isBusinessNumbers"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="地区">
                  <SelectCountry
                    v-model="queryParam.countryCode"
                    optionKey="countryCode"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="备注">
                  <a-input v-model="queryParam.remarks" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="最近活跃时间">
                  <a-range-picker
                    v-model="queryParam.lastLoginTime"
                    :show-time="{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'HH:mm:ss'),
                        moment('23:59:59', 'HH:mm:ss')
                      ]
                    }"
                    @change="changeLastLoginTime"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="更新时间">
                  <a-range-picker
                    v-model="queryParam.updateTime"
                    :show-time="{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'HH:mm:ss'),
                        moment('23:59:59', 'HH:mm:ss')
                      ]
                    }"
                    @change="changeUpdateTime"
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="6" :sm="24" class="table-page-search-wrapper">
              <div class="table-page-search-submitButtons">
                <a-space>
                  <a-button type="primary" @click="reloadTable">
                    查询
                  </a-button>
                  <a-button @click="resetSearchForm"> 重置 </a-button>
                  <a @click="toggleAdvanced">
                    {{ advanced ? '收起' : '展开' }}
                    <a-icon :type="advanced ? 'up' : 'down'" />
                  </a>
                </a-space>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-card :bordered="false" :body-style="{ padding: 0 }">
        <div class="ant-pro-table-toolbar">
          <div class="ant-pro-table-toolbar-title">
            <a-button type="primary" @click="handleAnalysis">
              分析重复
            </a-button>
          </div>
          <div class="ant-pro-table-toolbar-option">
            <a-button type="primary" @click="handleWhatsExport">
              导出客服号
            </a-button>
            <a-button type="primary" @click="handleNumberExport">
              导出号码
            </a-button>
          </div>
        </div>
        <a-table
          :row-key="rowKey"
          :columns="columns"
          size="middle"
          :data-source="dataSource"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ x: 1200, y: tabHeight }"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          }"
          @change="handleTableChange"
        >
          <template #platform-slot="text">
            <a-tag :color="findItem(platforms, text).color">
              {{ findItem(platforms, text).label }}
            </a-tag>
          </template>
          <template #avatar-slot="text">
            <a-avatar shape="square" size="small" icon="user" :src="text" />
          </template>
          <template #businessType-slot="text">
            <span>{{ findItem(isBusinessNumbers, text).label }}</span>
          </template>
          <template #status-slot="text">
            <a-tag :color="findItem(accountStates, text).color">
              {{ findItem(accountStates, text).label }}
            </a-tag>
          </template>
          <template #opeartion-slot="text, record">
            <a-space size="middle">
              <a @click="viewContacts(record)">查看联系人</a>
              <a @click="viewLogs(record)">查看日志</a>
            </a-space>
          </template>
        </a-table>
      </a-card>
    </div>
    <!-- 查看联系人 -->
    <viewContacts
      v-if="visible === 2"
      @back-to-page="visible = 1"
      :record="record"
    />
    <!-- 查看日志 -->
    <viewLog
      v-if="visible === 3"
      @back-to-page="visible = 1"
      :record="record"
    />
    <!-- 分析重复 -->
    <RepeatAnalysis
      v-if="visible === 4"
      @back-to-page="visible = 1"
      :whatsIdList="whatsIdList"
    />
  </div>
</template>

<script>
import viewContacts from '@/views/Home/viewContacts.vue'
import viewLog from '@/views/Home/viewLog.vue'
import RepeatAnalysis from '@/views/Home/repeatAnalysis.vue'
import StatisticsWithIcon from '@/components/StatisticsWithIcon'
import SelectCountry from '@/components/SelectCountry'
import {
  queryWaPage,
  queryStatistics,
  whatsAccountExport,
  fansCountDetailExport
} from '@/api/home'
import { TablePageMixin } from '@/mixins'
import {
  platforms,
  accountStates,
  isBusinessNumbers,
  findItem
} from './constants'
import { exportFiles } from '@/utils/util'

export default {
  name: 'HomePage',
  data() {
    return {
      getPage: queryWaPage,
      platforms,
      accountStates,
      isBusinessNumbers,
      findItem,
      // 1：当前页；2：查看联系人；3：查看日志；4：分析重复；
      visible: 1,
      record: {},
      statisticsCount: {}
    }
  },
  components: {
    StatisticsWithIcon,
    viewContacts,
    viewLog,
    RepeatAnalysis,
    SelectCountry
  },
  mixins: [TablePageMixin],
  computed: {
    statisticsData() {
      return [
        {
          icon: require('@/assets/statistics/statistics1.png'),
          bgColor: '#e8faea',
          title: '总数/联系人',
          count:
            (this.statisticsCount.sum || 0) +
            '/' +
            (this.statisticsCount.contactsCount || 0)
        },
        {
          icon: require('@/assets/statistics/statistics8.png'),
          bgColor: '#fdf3e9',
          title: '在线账号',
          count: this.statisticsCount.onlineCount || 0
        },
        {
          icon: require('@/assets/statistics/statistics9.png'),
          bgColor: '#f0f5fb',
          title: '离线账号',
          count: this.statisticsCount.offlineCount || 0
        },
        {
          icon: require('@/assets/statistics/statistics10.png'),
          bgColor: '#e7e1fb',
          title: '已封号',
          count: this.statisticsCount.sealCount || 0
        }
      ]
    },
    columns() {
      return [
        {
          title: '平台',
          dataIndex: 'platform',
          scopedSlots: { customRender: 'platform-slot' },
          align: 'center',
          width: 120
        },
        {
          title: '客服号',
          dataIndex: 'whatsId',
          align: 'center',
          width: 130
        },
        {
          title: '昵称',
          dataIndex: 'pushName',
          align: 'center',
          width: 120
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar-slot' },
          align: 'center',
          width: 100
        },
        {
          title: '地区',
          dataIndex: 'countryCode',
          align: 'center',
          width: 120
        },
        {
          title: 'WaChat状态',
          dataIndex: 'mobileOnlineStatus',
          scopedSlots: { customRender: 'status-slot' },
          align: 'center',
          width: 100
        },
        {
          title: '商业号',
          dataIndex: 'businessType',
          scopedSlots: { customRender: 'businessType-slot' },
          align: 'center',
          width: 100
        },
        {
          title: 'PC状态',
          dataIndex: 'survivalStatus',
          scopedSlots: { customRender: 'status-slot' },
          align: 'center',
          width: 100
        },
        {
          title: '联系人',
          dataIndex: 'contactsNum',
          align: 'center',
          width: 100
        },
        {
          title: '最近活跃时间',
          dataIndex: 'lastLoginTime',
          align: 'center',
          width: 150
        },
        {
          title: '备注',
          dataIndex: 'remarks',
          align: 'center',
          width: 130,
          ellipsis: true
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center',
          width: 150
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          align: 'center',
          width: 150
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'opeartion-slot' },
          align: 'center',
          width: 180
        }
      ]
    },
    whatsIdList() {
      return this.selectedRows.map((item) => item.whatsId)
    }
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    changeCreateTime(date, dateString) {
      this.queryParam.startCreateTime = dateString[0]
      this.queryParam.endCreateTime = dateString[1]
    },
    changeUpdateTime(date, dateString) {
      this.queryParam.startUpdateTime = dateString[0]
      this.queryParam.endUpdateTime = dateString[1]
    },
    changeLastLoginTime(date, dateString) {
      this.queryParam.startLastLoginTime = dateString[0]
      this.queryParam.endLastLoginTime = dateString[1]
    },
    getStatistics() {
      queryStatistics(this.queryParam).then((res) => {
        if (res.code === 200) {
          this.statisticsCount = res.data
        }
      })
    },
    // 查看联系人
    viewContacts(record) {
      this.visible = 2
      this.record = record
    },
    // 查看日志
    viewLogs(record) {
      this.visible = 3
      this.record = record
    },
    // 分析重复
    handleAnalysis() {
      if (!this.selectedRowKeys.length) {
        return this.$message.info('请选择列表')
      }
      this.visible = 4
    },
    handleWhatsExport() {
      exportFiles({
        request: whatsAccountExport,
        queryParam: this.queryParam
      })
    },
    handleNumberExport() {
      exportFiles({
        request: fansCountDetailExport,
        queryParam: this.queryParam
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-tag {
  margin-right: 0;
}
</style>

<template>
  <section>
    <a-card
      title="查看联系人"
      :bordered="false"
      :body-style="{ padding: '16px 24px' }"
      style="margin-bottom: 16px"
    >
      <template #extra>
        <a-button @click="$emit('back-to-page')">返回</a-button>
      </template>
      <a-descriptions :column="{ md: 2, lg: 3, xl: 4 }">
        <template #title>
          客服号信息（渠道号
          <span style="color: #d9001b">{{ channelSharing.channelNo }}</span
          >）
        </template>
        <a-descriptions-item label="平台">
          <a-tag :color="findItem(platforms, record.platform).color">
            {{ findItem(platforms, record.platform).label }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="客服号">
          {{ record.whatsId }}
        </a-descriptions-item>
        <a-descriptions-item label="昵称">
          {{ record.pushName }}
        </a-descriptions-item>
        <a-descriptions-item label="WaChat状态">
          <a-tag
            :color="findItem(accountStates, record.mobileOnlineStatus).color"
          >
            {{ findItem(accountStates, record.mobileOnlineStatus).label }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="PC状态">
          <a-tag :color="findItem(accountStates, record.survivalStatus).color">
            {{ findItem(accountStates, record.survivalStatus).label }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="头像">
          <a-avatar
            shape="square"
            size="small"
            icon="user"
            :src="record.avatar"
          />
        </a-descriptions-item>
        <a-descriptions-item label="地区">
          {{ record.countryCode }}
        </a-descriptions-item>
        <a-descriptions-item label="商业号">
          {{ findItem(isBusinessNumbers, record.businessType).label }}
        </a-descriptions-item>
        <a-descriptions-item label="创建时间">
          {{ record.createTime }}
        </a-descriptions-item>
        <a-descriptions-item label="最近活跃时间">
          {{ record.lastLoginTime }}
        </a-descriptions-item>
        <a-descriptions-item label="备注">
          <span :title="record.remarks" class="descriptions-ellipsis">
            {{ record.remarks }}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label="更新时间">
          {{ record.updateTime }}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <div class="ant-pro-table-search">
      <a-form
        :form="queryParam"
        v-bind="searchFormLayout"
        @keydown.enter.native="reloadTable"
      >
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="用户ID">
              <a-input v-model="queryParam.friendWhatsId" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="昵称">
              <a-input v-model="queryParam.pushName" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="是否补录">
              <a-select v-model="queryParam.supplementStatus" allow-clear>
                <a-select-option
                  v-for="item in isRecordings"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <template v-if="advanced">
            <a-col :md="6" :sm="24">
              <a-form-item label="创建时间">
                <a-range-picker
                  v-model="queryParam.createTime"
                  :show-time="{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00:00', 'HH:mm:ss'),
                      moment('23:59:59', 'HH:mm:ss')
                    ]
                  }"
                  @change="onTimeChange"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="地区">
                <SelectCountry
                  v-model="queryParam.countryCode"
                  optionKey="countryCode"
                />
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="6" :sm="24" class="table-page-search-wrapper">
            <div class="table-page-search-submitButtons">
              <a-space>
                <a-button type="primary" @click="reloadTable"> 查询 </a-button>
                <a-button @click="resetSearchForm"> 重置 </a-button>
                <a @click="toggleAdvanced">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </a-space>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-card :bordered="false" :body-style="{ padding: 0 }">
      <div class="ant-pro-table-toolbar" style="justify-content: flex-end">
        <div class="ant-pro-table-toolbar-option">
          <a-button type="primary" class="exports" @click="handleExport">
            导出
          </a-button>
        </div>
      </div>
      <a-table
        :row-key="rowKey"
        :columns="columns"
        size="middle"
        :data-source="dataSource"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ x: 1200, y: tabHeight }"
        @change="handleTableChange"
      >
        <template #avatar-slot="text">
          <a-avatar shape="square" size="small" icon="user" :src="text" />
        </template>
        <template #source-slot="text">
          <span>{{ findItem(sources, text).label }}</span>
        </template>
        <template #supplementStatus-slot="text">
          <span>{{ findItem(isRecordings, text).label }}</span>
        </template>
      </a-table>
    </a-card>
  </section>
</template>

<script>
import { TablePageMixin } from '@/mixins'
import SelectCountry from '@/components/SelectCountry'
import {
  accountStates,
  findItem,
  isRecordings,
  sources,
  platforms,
  isBusinessNumbers
} from './constants'
import {
  queryContactsPage,
  fansCountDetailExport,
  queryChannelSharing
} from '@/api/home'
import { exportFiles } from '@/utils/util'

export default {
  name: 'ViewContacts',
  data() {
    return {
      accountStates,
      findItem,
      isRecordings,
      sources,
      platforms,
      isBusinessNumbers,
      getPage: queryContactsPage,
      channelSharing: {}
    }
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [TablePageMixin],
  components: { SelectCountry },
  computed: {
    columns() {
      return [
        {
          title: '用户ID',
          dataIndex: 'friendWhatsId',
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'pushName',
          align: 'center'
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar-slot' },
          align: 'center'
        },
        {
          title: '地区',
          dataIndex: 'countryCode',
          align: 'center'
        },
        {
          title: '是否补录',
          dataIndex: 'supplementStatus',
          scopedSlots: { customRender: 'supplementStatus-slot' },
          align: 'center'
        },
        {
          title: '来源',
          dataIndex: 'source',
          scopedSlots: { customRender: 'source-slot' },
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createAt',
          align: 'center'
        }
      ]
    }
  },
  created() {
    this.queryParam.whatsId = this.record.whatsId
    this.getChannelSharing()
  },
  methods: {
    resetSearchForm() {
      this.queryParam = {
        whatsId: this.record.whatsId
      }
      this.reloadTable()
    },
    getChannelSharing() {
      queryChannelSharing({ whatsId: this.record.whatsId }).then((res) => {
        if (res.code === 200) {
          this.channelSharing = res.data
        }
      })
    },
    onTimeChange(date, dateString) {
      this.queryParam.startCreateTime = dateString[0]
      this.queryParam.endCreateTime = dateString[1]
    },
    handleExport() {
      exportFiles({
        request: fansCountDetailExport,
        queryParam: this.queryParam
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-tag {
  margin-right: 0;
}
</style>

<template>
  <section>
    <a-card title="分析重复" @back="$emit('back-to-page')">
      <template #extra>
        <a-button @click="$emit('back-to-page')">返回</a-button>
      </template>
    </a-card>
    <StatisticsWithIcon :data="statisticsData" style="margin: 16px 0" />
    <div class="ant-pro-table-search">
      <a-form
        :form="queryParam"
        v-bind="searchFormLayout"
        @keydown.enter.native="reloadTable"
      >
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="联系人">
              <a-input v-model="queryParam.friendWhatsId"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="昵称">
              <a-input v-model="queryParam.pushName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="地区">
              <SelectCountry
                v-model="queryParam.countryCode"
                optionKey="countryCode"
              />
            </a-form-item>
          </a-col>
          <template v-if="advanced">
            <a-col :md="6" :sm="24">
              <a-form-item label="创建时间">
                <a-range-picker
                  v-model="queryParam.createTime"
                  :show-time="{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00:00', 'HH:mm:ss'),
                      moment('23:59:59', 'HH:mm:ss')
                    ]
                  }"
                  allow-clear
                  @change="onTimeChange"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="重复次数">
                <a-select v-model="queryParam.count" allow-clear>
                  <a-select-option
                    v-for="item in repeatCounts"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="客服号">
                <a-input v-model="queryParam.whatsId"></a-input>
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="6" :sm="24" class="table-page-search-wrapper">
            <div class="table-page-search-submitButtons">
              <a-space>
                <a-button type="primary" @click="reloadTable"> 查询 </a-button>
                <a-button @click="resetSearchForm"> 重置 </a-button>
                <a @click="toggleAdvanced">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </a-space>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-card :bordered="false" :body-style="{ padding: 0 }">
      <div class="ant-pro-table-toolbar" style="justify-content: flex-end">
        <div class="ant-pro-table-toolbar-option">
          <a-button type="primary" class="exports" @click="handleExport">
            导出
          </a-button>
        </div>
      </div>
      <a-table
        :row-key="rowKey"
        :columns="columns"
        size="middle"
        :data-source="dataSource"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ x: 1200, y: tabHeight }"
        @change="handleTableChange"
      >
        <template #avatar-slot="text">
          <a-avatar shape="square" size="small" icon="user" :src="text" />
        </template>
      </a-table>
    </a-card>
  </section>
</template>

<script>
import { TablePageMixin } from '@/mixins'
import StatisticsWithIcon from '@/components/StatisticsWithIcon'
import SelectCountry from '@/components/SelectCountry'
import {
  queryAnalysis,
  queryAnalysisStatistics,
  repetitionExport
} from '@/api/home'
import { exportFiles } from '@/utils/util'
import { repeatCounts, findItem } from '@/views/Home/constants'

export default {
  name: 'RepeatAnalysis',
  data() {
    return {
      getPage: queryAnalysis,
      queryParam: {
        whatsIdList: this.whatsIdList
      },
      repetitionStatis: {},
      repeatCounts,
      findItem
    }
  },
  props: {
    whatsIdList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [TablePageMixin],
  components: { StatisticsWithIcon, SelectCountry },
  computed: {
    statisticsData() {
      return [
        {
          icon: require('@/assets/statistics/statistics13.png'),
          bgColor: '#e8faea',
          title: '重复率',
          count:
            (
              this.repetitionStatis.replicatesCount /
                this.repetitionStatis.sum || 0
            ).toFixed(3) *
              100 +
            '%'
        },
        {
          icon: require('@/assets/statistics/statistics2.png'),
          bgColor: '#fdf3e9',
          title: '重复人数/总数',
          count:
            (this.repetitionStatis.replicatesCount || 0) +
            '/' +
            (this.repetitionStatis.sum || 0)
        },
        {
          icon: require('@/assets/statistics/statistics11.png'),
          bgColor: '#f0f5fb',
          title: '按客服号维度排重',
          count: this.repetitionStatis.whatsIdDuplicate || 0
        },
        {
          icon: require('@/assets/statistics/statistics12.png'),
          bgColor: '#e7e1fb',
          title: '按照联系人维度排重',
          count: this.repetitionStatis.contactDuplicate || 0
        }
      ]
    },
    columns() {
      return [
        {
          title: '联系人',
          dataIndex: 'friendWhatsId',
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'pushName',
          align: 'center'
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar-slot' },
          align: 'center'
        },
        {
          title: '地区',
          dataIndex: 'countryCode',
          align: 'center'
        },
        {
          title: '重复次数',
          dataIndex: 'count',
          align: 'center'
        },
        {
          title: '归属客服号',
          dataIndex: 'whatsId',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        }
      ]
    }
  },
  mounted() {
    this.getAnalysisStatistics()
  },
  methods: {
    resetSearchForm() {
      this.queryParam = {
        whatsIdList: this.whatsIdList
      }
      this.reloadTable()
    },
    getAnalysisStatistics() {
      queryAnalysisStatistics(this.queryParam).then((res) => {
        if (res.code === 200) {
          this.repetitionStatis = res.data
        }
      })
    },
    onTimeChange(date, dateString) {
      this.queryParam.startCreateTime = dateString[0]
      this.queryParam.endCreateTime = dateString[1]
    },
    handleExport() {
      exportFiles({
        request: repetitionExport,
        queryParam: this.queryParam
      })
    }
  }
}
</script>

<style lang="less" scoped></style>

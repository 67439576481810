<template>
  <div>
    <van-popup
      v-model="visible"
      position="bottom"
      round
      closeable
      :style="{ height: '60%' }"
    >
      <h3 class="filter-title">全部筛选</h3>
      <van-form>
        <van-field v-model="params.nickname" label="昵称" />
        <van-field v-model="params.whatsId" label="客服号" />
        <Select v-model="params.countryCode" label="地区" :columns="regions" />
        <van-field name="radio" label="重复次数">
          <template #input>
            <Radio v-model="params.count" :columns="repeatCounts" />
          </template>
        </van-field>

        <van-row class="filter-footer" :gutter="16">
          <van-col :span="8">
            <van-button
              round
              block
              type="info"
              size="small"
              @click="handleReset"
            >
              重置
            </van-button>
          </van-col>
          <van-col :span="8">
            <van-button
              round
              block
              type="info"
              size="small"
              @click="handleSumbit"
            >
              确定
            </van-button>
          </van-col>
        </van-row>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import Select from '@/viewsPhone/components/Select'
import Radio from '@/viewsPhone/components/Radio'
import { platforms, accountStates, isBusiness, repeatCounts } from '@/views/Home/constants'
import { getCountrys } from '@/api/common'

export default {
  data() {
    return {
      visible: false,
      platforms,
      accountStates,
      isBusiness,
      repeatCounts,
      regions: [],
      params: this.queryParam
    }
  },
  components: { Select, Radio },
  props: {
    queryParam: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    queryParam() {
      this.params = this.queryParam
    }
  },
  methods: {
    show() {
      this.getRegions()
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    getRegions() {
      getCountrys().then((res) => {
        this.regions = res.data.map((item) => {
          return {
            label:
              item.areaCode + ' ' + item.name + ' (' + item.countryCode + ')',
            value: item.countryCode
          }
        })
      })
    },
    handleReset() {
      this.$emit('reset')
    },
    handleSumbit() {
      this.$emit('confirm')
      this.cancel()
    }
  }
}
</script>

<style lang="less" scoped>
.filter-title {
  text-align: center;
  padding: 10px;
}
.filter-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
}
</style>

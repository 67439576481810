import { message } from 'ant-design-vue'
import moment from 'moment'
import store from '@/store'
import { DEVICE_TYPE } from '@/utils/device'

export function onMessage(code) {
  switch (code) {
    case 200:
      message.success('成功')
      break
    case 404:
      message.info('需要分享码')
      break
    case 410:
      message.info('资源永久失效')
      break
    case 401:
      message.info('需要密码')
      break
    case 403:
      message.info('密码不正确')
      break
  }
}

// 导出文件
export function exportFiles({
  downlandText = moment().format('YYYYMMDDHHmmss'),
  request = () => new Promise(),
  queryParam = {},
  callback = () => {}
}) {
  request(queryParam)
    .then((res) => {
      let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
      let ObjectUrl = URL.createObjectURL(blob)
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = ObjectUrl
      a.download = downlandText
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(ObjectUrl)
    })
    .catch((err) => {
      message.error(err.response.data.error || 'error')
    })
    .finally(() => {
      callback()
    })
}

// 判断当前设备为pc
export function isDesktop() {
  return store.getters.device === DEVICE_TYPE.DESKTOP
}
// 判断当前设备为phone
export function isMobile() {
  return store.getters.device === DEVICE_TYPE.MOBILE
}

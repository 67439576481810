import axios from '@/utils/request'

export function queryWaPage(params) {
  return axios({
    url: '/external/getWhatsAccountPage',
    method: 'get',
    params
  })
}

export function queryContactsPage(params) {
  return axios({
    url: '/external/getFriendDetailPage',
    method: 'get',
    params
  })
}

export function queryLogPage(params) {
  return axios({
    url: '/external/getWhatsAccountLogPage',
    method: 'get',
    params
  })
}

export function queryStatistics(params) {
  return axios({
    url: '/external/getWhatsIdFansStatistics',
    method: 'get',
    params
  })
}

export function queryAnalysis(params) {
  return axios({
    url: '/external/repetitionAnalysis',
    method: 'get',
    params
  })
}

export function queryAnalysisStatistics(data) {
  return axios({
    url: '/external/repetitionAnalysisStatistics',
    method: 'post',
    data
  })
}

export function whatsAccountExport(params) {
  return axios({
    url: '/external/exportWhatsAccount',
    method: 'post',
    params,
    responseType: 'blob'
  })
}

export function fansCountDetailExport(params) {
  return axios({
    url: '/external/exportFansCountDetail',
    method: 'post',
    params,
    responseType: 'blob'
  })
}

export function whatsLogExport(params) {
  return axios({
    url: '/external/whatsAccountLogExport',
    method: 'post',
    params,
    responseType: 'blob'
  })
}

export function queryChannelSharing(params) {
  return axios({
    url: '/external/getChannelSharing',
    method: 'get',
    params,
  })
}

export function repetitionExport(params) {
  return axios({
    url: '/external/repetitionAnalysisExport',
    method: 'post',
    params,
    responseType: 'blob'
  })
}
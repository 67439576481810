import Vue from 'vue'
import router from '@/router'
import { SHARE_CODE } from '@/store/storage-types'

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next()
  } else {
    if (to.query.shareCode) {
      Vue.ls.set(SHARE_CODE, to.query.shareCode)
    }
    next()
  }
})

<template>
  <div>
    <home-page v-if="isDesktop()" />
    <phone-home-page v-else />
  </div>
</template>

<script>
import homePage from '@/views/Home/homePage.vue'
import phoneHomePage from '@/viewsPhone/Home/homePage.vue'
import { isDesktop } from '@/utils/util'

export default {
  data() {
    return {
      isDesktop
    }
  },
  components: { homePage, phoneHomePage }
}
</script>

<style lang="less" scoped></style>

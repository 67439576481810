<template>
  <div class="home-container">
    <div v-if="visible === 1">
      <van-sticky>
        <van-nav-bar
          :right-text="isManagement ? '取消管理' : '管理'"
          @click-right="handleManagement"
        >
          <template #left>
            <a-tooltip trigger="click" placement="bottomLeft">
              <template #title>
                <div>
                  在线:{{ statisticsCount.onlineCount || 0 }}
                  ,
                  离线:{{ statisticsCount.offlineCount || 0 }}
                  ,
                  封号:{{ statisticsCount.sealCount || 0 }}
                </div>
              </template>
              <div>
                <span style="color: #1989fa">
                  {{ statisticsCount.onlineCount || 0 }}
                </span>
                /
                <span style="color: #aaaaaa">
                  {{ statisticsCount.offlineCount || 0 }}
                </span>
                /
                <span style="color: #ee0a24">
                  {{ statisticsCount.sealCount || 0 }}
                </span>
              </div>
            </a-tooltip>
          </template>
          <template #title>
            总数/联系人：{{
              (statisticsCount.sum || 0) +
              '/' +
              (statisticsCount.contactsCount || 0)
            }}
          </template>
          <template #right>
            <span v-if="isManagement" style="color: #ee0a24">取消管理</span>
            <span v-else style="color: #1989fa">管理</span>
          </template>
        </van-nav-bar>
        <van-search
          v-model="queryParam.whatsId"
          placeholder="请输入客服号"
          show-action
          @search="reloadTable(true)"
        >
          <template #left>
            <div @click="handleFilter">筛选<van-icon name="filter-o" /></div>
          </template>
          <template #action>
            <div @click="reloadTable(true)">查询</div>
          </template>
        </van-search>
      </van-sticky>
      <!-- 筛选 -->
      <homeFilterPage
        ref="homeFilterPageRef"
        :queryParam="queryParam"
        @reset="queryParam = {}"
        @confirm="reloadTable(true)"
      />
      <!-- 列表 -->
      <van-pull-refresh v-model="loading" @refresh="resetSearchForm">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="reloadTable"
        >
          <van-checkbox-group
            v-model="selectedRowKeys"
            :class="{ 'is-not-management': !isManagement }"
            @change="handleCheckbox"
          >
            <van-checkbox
              v-for="item in dataSource"
              :key="item.id"
              :name="item.id"
            >
              <van-card>
                <template #title>
                  <div>
                    <span class="whats-id">{{ item.whatsId }}</span>
                    {{ '(' + item.pushName + ') ' + item.contactsNum }}
                  </div>
                </template>
                <template #thumb>
                  <a-avatar
                    shape="square"
                    icon="user"
                    :src="item.avatar"
                    :size="70"
                  />
                </template>
                <template #tags>
                  <a-space class="tags">
                    <van-tag :color="findItem(platforms, item.platform).color">
                      {{ findItem(platforms, item.platform).label }}
                    </van-tag>
                    <van-tag
                      :color="
                        findItem(accountStates, item.survivalStatus).color
                      "
                    >
                      {{ findItem(accountStates, item.survivalStatus).label }}
                    </van-tag>
                    <van-tag
                      :color="findItem(isBusiness, item.businessType).color"
                    >
                      {{ findItem(isBusiness, item.businessType).label }}
                    </van-tag>
                    {{ item.countryCode }}
                  </a-space>
                </template>
                <template #price>
                  {{ item.createTime }}
                </template>
                <template #num>
                  <a-space>
                    <van-icon
                      @click="handleContacts(item)"
                      name="contact-o"
                      :size="16"
                      color="#1890ff"
                    />
                    <van-icon
                      @click="handleLog(item)"
                      name="orders-o"
                      :size="16"
                      color="#1890ff"
                    />
                  </a-space>
                </template>
              </van-card>
            </van-checkbox>
          </van-checkbox-group>
        </van-list>
      </van-pull-refresh>
      <!-- 分析重复 -->
      <van-submit-bar
        v-if="isManagement"
        button-type="info"
        button-text="分析重复"
        :disabled="!selectedRowKeys.length"
        @submit="handleAnalysis"
      >
        <van-checkbox v-model="allSelect" @click="handleAllSelect">
          全选
        </van-checkbox>
        <span>已选 {{ this.selectedRowKeys.length }} 条</span>
      </van-submit-bar>
    </div>
    <!-- 查看联系人 -->
    <viewContacts v-if="visible === 2" :record="record" @goBack="visible = 1" />
    <!-- 查看日志 -->
    <viewLog v-if="visible === 3" :record="record" @goBack="visible = 1" />
    <!-- 分析重复 -->
    <repeatAnalysis
      v-if="visible === 4"
      :whatsIdList="whatsIdList"
      @goBack="
        visible = 1
        isManagement = false
      "
    />
  </div>
</template>

<script>
import { queryWaPage, queryStatistics } from '@/api/home'
import {
  platforms,
  accountStates,
  isBusiness,
  findItem
} from '@/views/Home/constants'
import { ListPageMixin } from '@/mixins'
import homeFilterPage from './homeFilterPage.vue'
import viewContacts from './viewContacts.vue'
import viewLog from './viewLog.vue'
import repeatAnalysis from './repeatAnalysis.vue'

export default {
  data() {
    return {
      getPage: queryWaPage,
      platforms,
      accountStates,
      isBusiness,
      findItem,
      // 1：当前页；2：查看联系人；3：查看日志；4：分析重复；
      visible: 1,
      record: {},
      statisticsCount: {},
      isManagement: false,
      allSelect: false
    }
  },
  components: { homeFilterPage, viewContacts, viewLog, repeatAnalysis },
  mixins: [ListPageMixin],
  computed: {
    whatsIdList() {
      const selectedRows = this.dataSource.filter((item) =>
        this.selectedRowKeys.includes(item.id)
      )
      return selectedRows.map((item) => item.whatsId)
    }
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    getStatistics() {
      queryStatistics(this.queryParam).then((res) => {
        if (res.code === 200) {
          this.statisticsCount = res.data
        }
      })
    },
    handleFilter() {
      this.$refs.homeFilterPageRef.show()
    },
    // 查看联系人
    handleContacts(record) {
      this.record = record
      this.visible = 2
    },
    // 查看日志
    handleLog(record) {
      this.record = record
      this.visible = 3
    },
    // 管理
    handleManagement() {
      this.isManagement = !this.isManagement
      this.selectedRowKeys = []
    },
    // 选择
    handleCheckbox() {
      this.allSelect = this.selectedRowKeys.length === this.dataSource.length
    },
    // 全选
    handleAllSelect() {
      if (this.allSelect) {
        this.selectedRowKeys = this.dataSource.map((item) => {
          return item.id
        })
      } else {
        this.selectedRowKeys = []
      }
    },
    // 分析重复
    handleAnalysis() {
      this.visible = 4
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  width: 100%;
  background-color: #fff;
}
::v-deep .is-not-management {
  .van-checkbox__icon {
    display: none;
  }
  .van-checkbox__label {
    margin-left: 0;
  }
}

::v-deep .van-checkbox {
  .van-checkbox__icon {
    margin-left: 8px;
  }
  .van-checkbox__label {
    display: block;
    width: 100%;
  }
}

.van-submit-bar__bar {
  justify-content: space-between;
}
</style>

<style lang="less">
.van-card {
  padding: 4px 16px;
  &:not(:first-child) {
    margin-top: 0;
  }
  .van-card__thumb {
    width: 70px;
    height: 70px;
  }
  .van-card__content {
    min-height: auto;
  }
  .tags {
    margin-top: 5px;
  }
  .whats-id {
    font-weight: bold;
  }
}
</style>

import moment from 'moment'
import { onMessage } from '@/utils/util';

export default {
  data() {
    return {
      // 主键 默认id
      rowKey: 'id',
      // 数据数组
      dataSource: [],
      // 数据加载动画
      loading: false,
      // 分页器设置
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showSizeChanger: true,
        showTotal: (total, range) => {
          let rangeBegin = range[0]
          let rangeEnd = range[1]
          return rangeBegin + '-' + rangeEnd + ' ' + '共' + total + '条'
        }
      },
      // 查询参数
      queryParam: {
        // languageType: 0
      },
      // 筛选参数字段
      filters: {},
      // 默认排序字段
      sortField: null,
      // 升序 asc/降序 desc
      sortOrder: null,
      // 表格展示
      tableShow: true,
      // 高级搜索 展开/关闭
      advanced: false,
      // 获取分页数据的方法
      getPage: function () {
        return Promise.resolve()
      },
      // 删除 数据的方法
      delObj: function () {},
      // 批量删除 数据的方法
      batchDeleteApi: function () {},
      // 已选中数据集合
      selectedRows: [],
      // 已选中的数据主键集合
      selectedRowKeys: [],
      // 延迟加载，created时不主动加载数据
      lazyLoad: false,
      // 搜索表单的布局
      searchFormLayout: {
        labelCol: {
          md: { span: 6 }
        },
        wrapperCol: {
          md: { span: 18 }
        }
      },
      tabHeight: 0
    }
  },
  mounted() {
    !this.lazyLoad && this.reloadTable()
  },
  created() {
    let serWidth = document.documentElement.clientWidth
    if (serWidth > 2400) {
      this.tabHeight = 960
    } else if (serWidth < 2400 && serWidth >= 2200) {
      this.tabHeight = 760
    } else if (serWidth < 2200 && serWidth >= 1900) {
      this.tabHeight = 560
    } else if (serWidth < 1900 && serWidth >= 1600) {
      this.tabHeight = 460
    } else {
      this.tabHeight = 360
    }
    this.pagination.pageSizeOptions = ['10', '30', '50', '100', '300', '500']
  },
  methods: {
    moment,
    /**
     * 表格重新加载方法
     * 如果参数为 true, 则强制刷新到第一页
     * @param bool
     */
    reloadTable(bool = false) {
      bool && (this.pagination.current = 1)
      this.loadData()
    },
    /**
     * 合并查询参数，分页参数，排序参数，过滤参数
     * @returns {{current: number, size: number} & {sortOrders: null, sortFields: null}}
     */
    pageParams: function () {
      Object.keys(this.queryParam).map((item) => {
        if (typeof this.queryParam[item] === 'string') {
          if (this.trim(this.queryParam[item]) === '') {
            this.queryParam[item] = undefined
          } else {
            this.queryParam[item] = this.trim(this.queryParam[item])
          }
        }
      })
      return Object.assign(
        {},
        this.queryParam,
        {
          current: this.pagination.current,
          size: this.pagination.pageSize
        },
        {
          // TODO 多列排序支持
          sortField: this.sortField,
          sortAsc: this.sortAsc
        },
        { ...this.filters }
      )
    },
    // 表格数据加载方法
    loadData() {
      const params = this.pageParams()
      this.loading = true
      if (typeof this.getPage !== 'function') return
      this.getPage(params)
        .then((res) => {
          if (res.code === 200) {
            const page = res.data
            // 为防止删除数据后导致页面当前页面数据长度为 0 ,自动翻页到上一页
            if (page.records) {
              if (page.records.length === 0 && this.pagination.current > 1) {
                this.pagination.current--
                this.loadData()
                return
              }
              this.dataSource = page.records
              this.pagination.total = page.total
              this.onPageLoadSuccess(page)
            } else {
              if (
                page.page.records.length === 0 &&
                this.pagination.current > 1
              ) {
                this.pagination.current--
                this.loadData()
                return
              }
              this.dataSource = page.page.records
              this.pagination.total = page.realCount
              this.onPageLoadSuccess(page)
            }
          } else {
            if (res.code === 50001) {
              this.pagination.current = 1
              this.loadData()
            }
            onMessage(res.code)
          }
        })
        .catch((e) => {
          // 未被 axios拦截器处理过，则在这里继续处理
          !e.resolved && this.$message.error(e.message || 'error request')
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 分页查询成功回调
     * @param page
     */
    onPageLoadSuccess() {},
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },
    /**
     * 分页、排序、筛选变化时进行数据更新
     * @param pagination
     * @param filters
     * @param sorter
     */
    handleTableChange(pagination, filters, sorter) {
      this.filters = filters
      sorter && sorter.field && (this.sortField = sorter.field)
      sorter && sorter.order && (this.sortAsc = sorter.order === 'ascend')
      this.pagination = pagination
      this.reloadTable()
    },
    // 展开/关闭 搜索条件
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    // 清空搜索条件
    resetSearchForm() {
      this.queryParam = {}
      this.reloadTable(true)
    },
    // 选择
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      if (selectedRowKeys.length === selectedRows.length) {
        this.selectedRows = selectedRows
      } else {
        this.selectedRows = selectedRowKeys.map(
          (key) =>
            this.selectedRows.find((row) => row.id === key) ||
            selectedRows.find((row) => row.id === key)
        )
      }
    },
    // 清空选项
    onClearSelected() {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    // 删除
    handleDel(params) {
      this.delObj(params)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.reloadTable()
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((e) => {
          // 未被 axios拦截器处理过，则在这里继续处理
          !e.resolved && this.$message.error(e.message || 'error request')
        })
    },
    // 批量删除
    handleBatchDel(params) {
      if (!this.selectedRowKeys.length) {
        return this.$message.info(this.$t('Lang.Pleaselist'))
      }
      this.$confirm({
        title: this.$t('Lang.Prompt'),
        content: this.$t('Lang.SureDelete'),
        okType: 'danger',
        onOk: () => {
          this.batchDeleteApi(params)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.reloadTable()
              } else {
                this.$message.info(res.message)
              }
            })
            .catch((e) => {
              // 未被 axios拦截器处理过，则在这里继续处理
              !e.resolved && this.$message.error(e.message || 'error request')
            })
        }
      })
    },
    // ========== 需要显示隐藏表格页时使用 ===================
    // 切换表格/表单
    switchPage() {
      window.scrollTo(0, 0)
      this.tableShow = !this.tableShow
    },
    // 返回表格
    backToPage(needRefresh) {
      this.switchPage()
      needRefresh && this.reloadTable()
    }
  }
}

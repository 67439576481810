<template>
  <section>
    <a-card
      title="查看日志"
      :bordered="false"
      :body-style="{ padding: '16px 24px' }"
      style="margin-bottom: 16px"
    >
      <template #extra>
        <a-button @click="$emit('back-to-page')">返回</a-button>
      </template>
      <a-descriptions :column="{ md: 2, lg: 3, xl: 4 }">
        <template #title>
          客服号信息（渠道号<span style="color: #d9001b">{{
            channelSharing.channelNo
          }}</span
          >）
        </template>
        <a-descriptions-item label="平台">
          <a-tag :color="findItem(platforms, record.platform).color">
            {{ findItem(platforms, record.platform).label }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="客服号">
          {{ record.whatsId }}
        </a-descriptions-item>
        <a-descriptions-item label="昵称">
          {{ record.pushName }}
        </a-descriptions-item>
        <a-descriptions-item label="WaChat状态">
          <a-tag
            :color="findItem(accountStates, record.mobileOnlineStatus).color"
          >
            {{ findItem(accountStates, record.mobileOnlineStatus).label }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="PC状态">
          <a-tag :color="findItem(accountStates, record.survivalStatus).color">
            {{ findItem(accountStates, record.survivalStatus).label }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="头像">
          <a-avatar
            shape="square"
            size="small"
            icon="user"
            :src="record.avatar"
          />
        </a-descriptions-item>
        <a-descriptions-item label="地区">
          {{ record.countryCode }}
        </a-descriptions-item>
        <a-descriptions-item label="商业号">
          {{ findItem(isBusinessNumbers, record.businessType).label }}
        </a-descriptions-item>
        <a-descriptions-item label="创建时间">
          {{ record.createTime }}
        </a-descriptions-item>
        <a-descriptions-item label="最近活跃时间">
          {{ record.lastLoginTime }}
        </a-descriptions-item>
        <a-descriptions-item label="备注">
          <span :title="record.remarks" class="descriptions-ellipsis">{{
            record.remarks
          }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="更新时间">
          {{ record.updateTime }}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <div class="ant-pro-table-search">
      <a-form
        :form="queryParam"
        v-bind="searchFormLayout"
        @keydown.enter.native="reloadTable"
      >
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" allow-clear>
                <a-select-option
                  v-for="item in accountStates"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="设备">
              <a-select v-model="queryParam.equipment" allow-clear>
                <a-select-option
                  v-for="item in equipments"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="行为类型">
              <a-select v-model="queryParam.detailStatus" allow-clear>
                <a-select-option
                  v-for="item in behaviorTypes"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker
                v-model="queryParam.createTime"
                :show-time="{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'),
                    moment('23:59:59', 'HH:mm:ss')
                  ]
                }"
                @change="onTimeChange"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24" class="table-page-search-wrapper">
            <div class="table-page-search-submitButtons">
              <a-space>
                <a-button type="primary" @click="reloadTable"> 查询 </a-button>
                <a-button @click="resetSearchForm"> 重置 </a-button>
              </a-space>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-card :bordered="false" :body-style="{ padding: 0 }">
      <div class="ant-pro-table-toolbar">
        <div class="ant-pro-table-toolbar-title">
          <span style="color: red">显示最近50条</span>
        </div>
        <div class="ant-pro-table-toolbar-option">
          <a-button type="primary" class="exports" @click="handleExport">
            导出
          </a-button>
        </div>
      </div>
      <a-table
        :row-key="rowKey"
        :columns="columns"
        size="middle"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1200, y: tabHeight }"
        @change="handleTableChange"
      >
        <template #equipment-slot="text">
          <span>{{ findItem(equipments, text).label }}</span>
        </template>
        <template #status-slot="text">
          <a-tag :color="findItem(accountStates, text).color">
            {{ findItem(accountStates, text).label }}
          </a-tag>
        </template>
        <template #detailStatus-slot="text">
          <span>{{ findItem(behaviorTypes, text).label }}</span>
        </template>
      </a-table>
      <div class="pagination-box">
        {{ '共 ' + logCount + ' 条' }}
      </div>
    </a-card>
  </section>
</template>

<script>
import { TablePageMixin } from '@/mixins'
import { queryLogPage, whatsLogExport, queryChannelSharing } from '@/api/home'
import {
  accountStates,
  findItem,
  isRecordings,
  equipments,
  behaviorTypes,
  platforms,
  isBusinessNumbers
} from './constants'
import { exportFiles } from '@/utils/util'

export default {
  name: 'ViewLog',
  data() {
    return {
      accountStates,
      findItem,
      isRecordings,
      equipments,
      behaviorTypes,
      platforms,
      isBusinessNumbers,
      getPage: queryLogPage,
      channelSharing: {}
    }
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [TablePageMixin],
  computed: {
    columns() {
      return [
        {
          title: '设备',
          dataIndex: 'equipment',
          scopedSlots: { customRender: 'equipment-slot' },
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status-slot' },
          align: 'center'
        },
        {
          title: '行为类型',
          dataIndex: 'detailStatus',
          scopedSlots: { customRender: 'detailStatus-slot' },
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        }
      ]
    },
    logCount() {
      return (this.pagination.total < 50 ? this.pagination.total : 50) || 0
    }
  },
  created() {
    this.queryParam.whatsId = this.record.whatsId
    this.pagination = {
      current: 1,
      pageSize: 50
    }
    this.getChannelSharing()
  },
  methods: {
    resetSearchForm() {
      this.queryParam = {
        whatsId: this.record.whatsId
      }
      this.pagination = {
        current: 1,
        pageSize: 50
      }
      this.reloadTable()
    },
    getChannelSharing() {
      queryChannelSharing({ whatsId: this.record.whatsId }).then((res) => {
        if (res.code === 200) {
          this.channelSharing = res.data
        }
      })
    },
    onTimeChange(date, dateString) {
      this.queryParam.startCreateTime = dateString[0]
      this.queryParam.endCreateTime = dateString[1]
    },
    handleExport() {
      exportFiles({
        request: whatsLogExport,
        queryParam: this.queryParam
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-tag {
  margin-right: 0;
}
.pagination-box {
  padding: 16px;
  text-align: right;
}
</style>

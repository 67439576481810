<template>
  <a-result
    :status="status"
    :title="types[status].title"
    :sub-title="types[status].desc"
  >
  </a-result>
</template>

<script>
export default {
  data() {
    return {
      types: {
        403: {
          title: '403',
          desc: 'Sorry, you are not authorized to access this page.'
        },
        404: {
          title: '404',
          desc: 'Sorry, the page you visited does not exist.'
        },
        500: {
          title: '500',
          desc: 'Sorry, the server is wrong.'
        }
      }
    }
  },
  computed: {
    status() {
      return this.$route.name || 404
    }
  }
}
</script>

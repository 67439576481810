import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '@/views/LoginPage.vue'
import Exception from '@/views/exception'
import Home from '@/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/403',
    name: '403',
    component: Exception
  },
  {
    path: '/404',
    name: '404',
    component: Exception
  },
  {
    path: '/500',
    name: '500',
    component: Exception
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

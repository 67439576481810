<template>
  <div>
    <van-sticky>
      <van-nav-bar
        :title="'查看日志(' + record.whatsId + ')'"
        left-text="返回"
        left-arrow
        @click-left="handleBack"
      />
    </van-sticky>
    <!-- 列表 -->
    <van-pull-refresh v-model="loading" @refresh="resetSearchForm">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="reloadTable(true)"
      >
        <van-cell-group>
          <van-cell
            v-for="item in dataSource"
            :key="item.id"
            :label="item.createTime"
          >
            <template #title>
              <van-tag :color="findItem(accountStates, item.status).color">
                {{ findItem(accountStates, item.status).label }}
              </van-tag>
            </template>
            <template #default>
              {{ findItem(behaviorTypes, item.detailStatus).label }}
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { queryLogPage } from '@/api/home'
import { ListPageMixin } from '@/mixins'
import { accountStates, behaviorTypes, findItem } from '@/views/Home/constants'

export default {
  data() {
    return {
      getPage: queryLogPage,
      accountStates,
      behaviorTypes,
      findItem
    }
  },
  mixins: [ListPageMixin],
  props: {
    record: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.queryParam.whatsId = this.record.whatsId
    this.pagination = {
      current: 1,
      pageSize: 50
    }
  },
  methods: {
    handleBack() {
      this.$emit('goBack')
    },
    resetSearchForm() {
      this.queryParam.whatsId = this.record.whatsId
      this.reloadTable(true)
    },
    onPageBeforeLoad() {
      this.loading = false
      this.finished = true
    }
  }
}
</script>

<style lang="less" scoped></style>

import Vue from 'vue'
import {
  Button,
  Form,
  Input,
  Card,
  Select,
  Row,
  Col,
  Icon,
  Space,
  DatePicker,
  Table,
  message,
  PageHeader,
  Descriptions,
  Divider,
  Avatar,
  Tag,
  ConfigProvider,
  Result,
  Tooltip
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

Vue.use(Button)
Vue.use(Form)
Vue.use(Input)
Vue.use(Card)
Vue.use(Select)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)
Vue.use(Space)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(PageHeader)
Vue.use(Descriptions)
Vue.use(Divider)
Vue.use(Avatar)
Vue.use(Tag)
Vue.use(ConfigProvider)
Vue.use(Result)
Vue.use(Tooltip)
Vue.prototype.$message = message

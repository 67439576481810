<template>
  <a-card :bordered="false" :bodyStyle="{ padding: '16px' }">
    <div class="container">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="item"
        :style="{ backgroundColor: item.bgColor }"
      >
        <div class="item-img">
          <img :src="item.icon" alt="" />
        </div>
        <div class="item-content">
          <h5 :title="item.title">{{ item.title }}</h5>
          <h3 :title="item.count">{{ item.count }}</h3>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
export default {
  name: 'StatisticsWithIcon',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>

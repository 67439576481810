<template>
  <div>
    <van-sticky>
      <van-nav-bar left-text="返回" left-arrow @click-left="handleBack">
        <template #title> 分析重复 </template>
      </van-nav-bar>
      <van-nav-bar>
        <template #left>
          重复率:{{
            (
              repetitionStatis.replicatesCount / repetitionStatis.sum || 0
            ).toFixed(3) *
              100 +
            '%'
          }}
        </template>
        <template #right>
          重复人数/总数({{
            (repetitionStatis.replicatesCount || 0) +
            '/' +
            (repetitionStatis.sum || 0)
          }})
        </template>
      </van-nav-bar>
      <van-nav-bar>
        <template #left>
          客服号维度排重:{{ repetitionStatis.whatsIdDuplicate || 0 }}
        </template>
        <template #right>
          联系人维度排重:{{ repetitionStatis.contactDuplicate || 0 }}
        </template>
      </van-nav-bar>
      <van-search
        v-model="queryParam.friendWhatsId"
        placeholder="请输入联系人客服号"
        show-action
        @search="reloadTable(true)"
      >
        <template #left>
          <div @click="handleFilter">筛选<van-icon name="filter-o" /></div>
        </template>
        <template #action>
          <div @click="reloadTable(true)">查询</div>
        </template>
      </van-search>
    </van-sticky>
    <!-- 筛选 -->
    <repeatFilterPage
      ref="repeatFilterPageRef"
      :queryParam="queryParam"
      @reset="resetFilter"
      @confirm="reloadTable(true)"
    />
    <!-- 列表 -->
    <van-pull-refresh v-model="loading" @refresh="resetSearchForm">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="reloadTable"
      >
        <van-card v-for="item in dataSource" :key="item.id">
          <template #title>
            <span class="whats-id">{{ item.friendWhatsId }}</span>
            {{ '(' + item.pushName + ')' + ' ' + item.whatsId }}
          </template>
          <template #thumb>
            <a-avatar
              shape="square"
              icon="user"
              :src="item.avatar"
              :size="70"
            />
          </template>
          <template #tags>
            <div class="tags">
              重复 <span style="color: #ee0a24">{{ item.count }}</span> 次
            </div>
          </template>
          <template #price>
            {{ item.countryCode }}
          </template>
          <template #num>
            {{ item.createTime }}
          </template>
        </van-card>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ListPageMixin } from '@/mixins'
import { queryAnalysis, queryAnalysisStatistics } from '@/api/home'
import repeatFilterPage from './repeatFilterPage.vue'

export default {
  data() {
    return {
      getPage: queryAnalysis,
      queryParam: {
        whatsIdList: this.whatsIdList
      },
      repetitionStatis: {}
    }
  },
  components: { repeatFilterPage },
  mixins: [ListPageMixin],
  props: {
    whatsIdList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.getAnalysisStatistics()
  },
  methods: {
    handleFilter() {
      this.$refs.repeatFilterPageRef.show()
    },
    resetFilter() {
      this.queryParam = {
        whatsIdList: this.whatsIdList
      }
    },
    getAnalysisStatistics() {
      queryAnalysisStatistics(this.queryParam).then((res) => {
        if (res.code === 200) {
          this.repetitionStatis = res.data
        }
      })
    },
    reloadSearchTable() {
      this.reloadTable()
    },
    resetSearchForm() {
      this.queryParam = {
        whatsIdList: this.whatsIdList
      }
      this.reloadTable()
    },
    handleBack() {
      this.$emit('goBack')
    }
  }
}
</script>

<style lang="less" scoped></style>

export default {
  data() {
    return {
      getPage: function () {
        return Promise.resolve()
      },
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10
      },
      queryParam: {},
      finished: false,
      loading: false,
      selectedRowKeys: []
    }
  },
  methods: {
    /**
     * 表格重新加载方法
     * 如果参数为 true, 则强制刷新到第一页
     * @param bool
     */
    reloadTable(bool = false) {
      if (bool) {
        this.pagination.current = 1
      }
      this.loadData(bool)
    },
    loadData(bool) {
      this.onPageBeforeLoad()
      const params = this.pageParams()
      this.loading = true
      this.getPage(params)
        .then((res) => {
          this.pagination.current++
          if (bool) {
            this.finished = false
            this.dataSource = res.data.records
          } else {
            this.dataSource = [...this.dataSource, ...res.data.records]
          }
          if (this.dataSource.length === res.data.total) {
            this.finished = true
          }
          this.onPageLoadSuccess(res.data)
        })
        .catch(() => {
          this.finished = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 合并查询参数，分页参数，排序参数，过滤参数
     * @returns {{current: number, size: number} & {sortOrders: null, sortFields: null}}
     */
    pageParams() {
      return Object.assign({}, this.queryParam, {
        current: this.pagination.current,
        size: this.pagination.pageSize
      })
    },
    onPageBeforeLoad() {},
    onPageLoadSuccess() {},
    // 清空搜索条件
    resetSearchForm() {
      this.queryParam = {}
      this.reloadTable(true)
    }
  }
}

<template>
  <section>
    <div
      class="login-container"
      :style="{ width: isDesktop() ? '30%' : '100%' }"
    >
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item>
          <span class="title">输入密码进入分享页面</span>
        </a-form-item>
        <a-form-item label="密码">
          <a-input-password
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入密码' }]
              }
            ]"
            placeholder="请输入密码"
            autocomplete=""
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit"> 登录 </a-button>
        </a-form-item>
      </a-form>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { checkPass } from '@/api/login'
import { onMessage } from '@/utils/util'
import { PASSWORD } from '@/store/storage-types'
import { isDesktop } from '@/utils/util'

export default {
  name: 'LoginPage',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      isDesktop
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          checkPass(values.password).then((res) => {
            if (res.code === 200) {
              Vue.ls.set(PASSWORD, values.password)
              this.$router.push({
                path: '/'
              })
            }
            onMessage(res.code)
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .login-container {
    min-width: 300px;
    background-color: #fff;
    padding: 16px 24px;
    border: 1px solid #e5e6eb;
    border-radius: 10px;
    margin-top: 8px;
    .title {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>

<template>
  <div>
    <van-button
      v-for="item in columns"
      :key="item.value"
      :type="selectedValue === item.value ? 'info' : 'default'"
      @click="handleClick(item.value)"
    >
      {{ item.label }}
    </van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedValue: this.value
    }
  },
  props: {
    value: {
      type: [String, Number]
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    value() {
      this.selectedValue = this.value
    }
  },
  methods: {
    handleClick(value) {
      if (this.selectedValue === value) {
        this.selectedValue = undefined
      } else {
        this.selectedValue = value
      }
      this.$emit('input', this.selectedValue)
    }
  }
}
</script>

<style lang="less" scoped></style>

import Vue from 'vue'
import './antdvue'
import './vant'
import '@/styles/ballcat.less'
import VueStorage from 'vue-ls'

const storageOptions = {
  namespace: 'share/', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'session' // storage name session, local, memory
}

// 页面标题
document.title = 'channel-share'

Vue.use(VueStorage, storageOptions)

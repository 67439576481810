import Vue from 'vue'
import {
  List,
  Card,
  Tag,
  Button,
  Icon,
  Search,
  Popup,
  Form,
  Field,
  Row,
  Col,
  Picker,
  Empty,
  PullRefresh,
  NavBar,
  Cell,
  CellGroup,
  Sticky,
  SwipeCell,
  CheckboxGroup,
  Checkbox,
  SubmitBar
} from 'vant'
import 'vant/lib/index.css'

Vue.use(List)
Vue.use(Card)
Vue.use(Tag)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Search)
Vue.use(Popup)
Vue.use(Form)
Vue.use(Field)
Vue.use(Row)
Vue.use(Col)
Vue.use(Picker)
Vue.use(Empty)
Vue.use(PullRefresh)
Vue.use(NavBar)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Sticky)
Vue.use(SwipeCell)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(SubmitBar)

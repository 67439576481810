<template>
  <a-select
    :value="selectedValue"
    :placeholder="placeholder"
    :show-search="showSearch"
    :allow-clear="allowClear"
    :filter-option="filterOption"
    @change="handleChange"
  >
    <a-select-option v-for="item in regions" :key="item[optionKey]">
      <!-- {{ item.areaCode + ' ' + (lang === 'zh-CN' ? item.name : item.enName) + ' (' + item.countryCode + ')' }} -->
      {{ item.areaCode + ' ' + item.name + ' (' + item.countryCode + ')' }}
    </a-select-option>
  </a-select>
</template>

<script>
import { getCountrys } from '@/api/common'

export default {
  data() {
    return {
      regions: [],
      // lang: JSON.parse(localStorage.getItem('scrm/app_language')).value,
      selectedValue: this.value
    }
  },
  props: {
    optionKey: {
      type: String,
      default: 'id'
    },
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: String,
      default: ''
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value() {
      this.selectedValue = this.value
    }
  },
  mounted() {
    this.getRegions()
    // this.$bus.$on('switch-language', data => {
    //   this.lang = data
    // })
  },
  methods: {
    // 获取地区
    getRegions() {
      getCountrys().then((res) => {
        this.regions = res.data
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleChange(val) {
      if (val && val.target) {
        this.selectedValue = val.target.value
      } else {
        this.selectedValue = val
      }
      // v-decorator 方式的表单值联动
      this.$emit('change', this.selectedValue)
      // v-model 方式的表单值联动
      this.$emit('input', this.selectedValue)
    }
  }
}
</script>

<style lang="less" scoped></style>

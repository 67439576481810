import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import notification from 'ant-design-vue/es/notification'
import { PASSWORD, SHARE_CODE } from '@/store/storage-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
  timeout: 600000 // 请求超时时间
})

// 请求失败处理函数
const onRejected = (error) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
      error.resolved = true
    }
    if (error.response.status === 401) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      error.resolved = true
    }
    if (error.response.status === 500) {
      const route = router.history.current.path
      if (route !== '/login') {
        router.push(
          {
            path: '/404'
          },
          () => {}
        )
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use((config) => {
  const headers = config.headers
  const password = Vue.ls.get(PASSWORD)
  const shareCode = Vue.ls.get(SHARE_CODE)
  if (!headers.pass) {
    headers.pass = password
  }
  headers.shareCode = shareCode
  return config
}, onRejected)

// response interceptor
service.interceptors.response.use((response) => {
  const headers = response.headers
  if (
    headers != null &&
    headers['content-type'] &&
    headers['content-type'].startsWith('application/json')
  ) {
    let data = response.data
    onResponse(data)
    return data
  } else {
    return response
  }
}, onRejected)

function onResponse(data) {
  const route = router.history.current.path
  if (data.code === 401) {
    if (route !== '/login') {
      router.push({
        path: '/login'
      })
    }
  }
  if (data.code === 403) {
    if (route !== '/login') {
      router.push({
        path: '/login'
      })
    }
  }
  if (data.code === 410) {
    if (route !== '/login') {
      router.push(
        {
          path: '/404'
        },
        () => {}
      )
    }
  }
}

export default service

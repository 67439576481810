<template>
  <div id="app">
    <a-config-provider :locale="zh_CN">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
import { deviceEnquire } from '@/utils/device'
import { APP_MUTATIONS } from '@/store/storage-types'
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      zh_CN
    }
  },
  mounted() {
    deviceEnquire((deviceType) => {
      // 切换设备类型
      this[APP_MUTATIONS.TOGGLE_DEVICE](deviceType)
    })
  },
  methods: {
    ...mapMutations([
      APP_MUTATIONS.TOGGLE_DEVICE,
      APP_MUTATIONS.TOGGLE_SIDE_BAR_COLLAPSED
    ])
  }
}
</script>

<style lang="less" scoped>
#app {
  height: 100%;
  background-color: #f0f2f5;
}
</style>
